<template>
  <v-app
    ><v-main>
      <div id="misc">
        <img
          class="misc-mask"
          height="226"
          :src="
            require(`@/assets/images/misc/misc-mask-${
              $vuetify.theme.dark ? 'dark' : 'light'
            }.png`)
          "
        />

        <!-- <v-img class="misc-tree" src="@/assets/images/misc/tree-4.png"></v-img> -->

        <div class="page-title text-center px-5">
          <h2
            class="
              text-2xl
              font-weight-semibold
              text--primary
              d-flex
              align-center
              justify-center
            "
          >
            <span class="me-2 text-h3">Page Not Found</span>
            <v-icon color="warning"> {{ icons.mdiAlert }} </v-icon>
          </h2>
          <p class="text-lg">网页找不到了</p>

           <v-btn color="primary" to="/" class="mb-4 mt-5" x-large> 回到首页 </v-btn>

          <div class="misc-character d-flex justify-center">
            <v-img
              max-width="700"
              src="@/assets/images/3d-characters/error.png"
            ></v-img>
          </div>

         
        </div>
      </div>
    </v-main></v-app
  >
</template>

<script>
import { mdiAlert } from '@mdi/js'
export default {
  data() {
    return {
      icons: {
        mdiAlert
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
